<template>
  <div class="user-box">
    <s-header :name="tit"></s-header>
    <div class="user-info">
      <!-- <span>学习进度：0/{{total}}</span> -->
    </div>
    <div class="bottoms">
      <div v-if="imgShow" class="imgstyle">
        <img :src="urlSrc" alt="">
      </div>
      <div v-if="pdfShow" class="pdfstyle">
        <div class="vwhs">
          <div id="oooos"></div>
        </div>
        

         <!-- <pdf v-for="i in numPages" :key="i" :src="src" :page="i"></pdf> -->

        <!-- <iframe class="iframepdf" ref="mainiframe" :src="urlSrc +'#toolbar=0'" frameborder="0" style="margin-top: -34px;height:100%;width:100%" ></iframe> -->
        <!-- <iframe class="iframepdf" ref="mainiframe" :src="'//www.xdocin.com/xdoc?_func=to&_format=html&_cache=1&_xdoc=' + urlSrc +'#toolbar=0'" frameborder="0" style="height:100%;width:100%" ></iframe> -->
        
        <!-- <iframe class="iframepdf" ref="mainiframe" :src="urlSrc +'#toolbar=0'" frameborder="0" style="margin-top: -34px;height:100%;width:100%" ></iframe> -->
    

      </div>
        
    </div>

    
    <van-overlay :show="load" @click="load = false">
      <van-loading type="spinner" class="centsty" />
    </van-overlay>
  </div>
</template>

<script>
import { tokenChang } from '@/common/js/utils'
import { Pagination, Toast, Loading, Overlay } from 'vant';
import sHeader from '@/components/SimpleHeader';
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";
import pdf from 'vue-pdf'

export default {
  components: {
    sHeader,
    // pdf
  },
  data() {
    return {
      user: {},
      load: true,
      imgShow: false,
      pdfShow: false,
      urlSrc: '',
      pdfh5: null,
      tit: '',
      numPages: '',
      src: '',
    }
  },

  mounted() {
    this.src = '';
    tokenChang();  // 刷新token
    // console.log(this.$route.query);
    this.tit = this.$route.query.tit;
    let nameType = this.$route.query.nameType;
    // 图片格式 jpg,png,jpeg
    if (nameType === 'jpg' || nameType === 'png' || nameType === 'jpeg' ){
      // console.log('图片');
      this.$nextTick(() => {
        this.imgShow = true;
        this.load = false;
      })
      this.urlSrc = this.$route.query.urlData;
    }
    if (nameType === 'pdf'){
      // this.src = pdf.createLoadingTask(this.$route.query.urlData)
      this.pdfShow = true;
      this.load = false;
      this.$nextTick(() => {
        
        
        this.pdfh5 = new Pdfh5('#oooos', {
          pdfurl: this.$route.query.urlData
        });
        // console.log(this.pdfh5,'this.pdfh5');
        // this.pdfh5.on("complete", function (status, msg, time) {
        //   console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
        // });
        // pdf 监听加载失败，msg信息，time耗时
        // this.pdfh5.on("error", (msg, time) => {
        //   console.log("pdf加载失败", "信息：", msg, ", 耗时：", time, "毫秒");
        // });
        // this.pdfh5.on("start",function(str){
        //     console.log(str,'start')
        // })


        
      });
      // this.urlSrc = this.$route.query.urlData;
      this.urlSrc = this.$route.query.urlData;
      
    }
    if (nameType === 'ppt' || nameType === 'pptx'){
      this.$nextTick(() => {
        this.pdfShow = true;
        this.load = false;
        
      });
      // this.urlSrc = '//www.xdocin.com/xdoc?_func=to&_format=html&_cache=1&_xdoc=' + this.$route.query.urlData;
      this.urlSrc = this.$route.query.urlData;
    }
    if (nameType === 'doc' || nameType === 'docx'){
      this.$nextTick(() => {
        this.pdfShow = true;
        this.load = false;
        this.urlSrc = this.$route.query.urlData;
      });

      // this.urlSrc = 'http://view.xdocin.com/xdoc?_xdoc=http://36.134.79.236:9090/storage/uploads/files/V8ppopm6KdWIfMtpinaHKvAeKAtHPMcH5mWy0OnG.doc';
    }

    // this.$nextTick(() => {
    //   this.src.promise.then(pdf => {
    //     this.numPages = pdf.numPages;
    //   });
    // })
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    goTo(r) {
      this.$router.push({ path: r })
    },
    Chacktypefun(name) {
      return /\.(gif|jpg|jpeg|png)$/i.test(name)
    },
  }
}
</script>
<style>
#mainContainer {
  top: -34px;
}
#_docbar {
  display: none !important;
}
#toolbarContainer {
  display: none !important;
}
.toolbar {
  display: none !important;
}
.iframepdf {
  width: 105% !important;
  height: 100% !important;
}
</style>
<style lang="less" scoped>
@import '../common/style/mixin';
.oooos {
  // width: 90%;
  // height: 10%;
  // position: ab;
  // background-color: #fff;
}
.vwhs {
  width: 90vw;
  height: 100vh;
}
.centsty {
  // margin: 0 auto;
  margin-left: 48%;
  margin-top: 48%;
}
.pdfstyle {
  width: 100%;
  height: 105%;
  overflow: hidden;
  
}
.imgstyle {
  width: 100%;
  height: 100%;
  img {
    // display:block;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    // transform:scale(1);
    // object-fit: cover;
    // object-fit: none;


    // image-rendering:-moz-crisp-edges;
    // image-rendering:-o-crisp-edges;
    // image-rendering:-webkit-optimize-contrast;
    // image-rendering: crisp-edges;
    // -ms-interpolation-mode:nearest-neighbor;

    // object-fit: fill; 
    // object-fit: contain;
    // object-fit: scale-down;
  


    
  }
}
.pagcss {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.user-box {
  .user-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    .fj();
    .wh(100%, 44px);
    line-height: 44px;
    padding: 0 10px;
    // padding-top: 60px;
    .boxSizing();
    color: #252525;
    background: #fff;
    border-bottom: 1px solid #dcdcdc;
    .user-name {
      font-size: 14px;
    }
  }
  .user-info {
    width: 80%;
    margin: 0 auto;
    height: 50px;
    // margin-top: 50px;
    line-height: 40px;
    font-size: 16px;
    text-align: right;
  }
}
.bottoms {
  width: 90%;
  // height: 100%;
  height: 86%;
  margin: 0 auto;
  padding-bottom: 20px;
  // background-color: yellow;
}
.wordimg {
  margin-top: 10px;
  height: 70px;
  border-radius: 6px;
  background-color: #ccc;
  .imgs {
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    width: 50px;
    height: 50px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .textr {
    text-align: right;
  }
}
</style>
