<template>
  <div class="user-box">
    <!-- <s-header :name="'考试'"></s-header> -->
    <header class="simple-header">
      <i class="nbicon nbfanhui" @click="goBack"></i>
      <div class="simple-header-name">{{ name }}</div>
      <i class="nbicon"></i>
    </header>
    <div class="titHead">
      <van-count-down  format="mm:ss" class="textr" :time="time" @finish="finish" />
      <!-- <span>本次考试60分钟，满分100分，剩余考试次数0</span> -->
    </div>
    <div class="bottoms" >
    <div>
      <div class="tite">一、单选题</div>
      <div v-for="(item,index) in billList" :key="index" class="mgtbi">
        <div class="textlp">{{index + 1}}.{{item.title}}</div>
        <div>
          <!-- <van-checkbox-group v-model="item.result" :max="1">
            <van-checkbox v-for="(cont,inkey) in item.contents" :key="inkey" :name="inkey">{{cont}}</van-checkbox>
          </van-checkbox-group> -->
          <van-radio-group v-model="item.results">
            <van-radio v-for="(cont,inkey) in item.contents" :key="inkey" :name="inkey" icon-size="20px" class="textlp mgtop">{{cont}}</van-radio>
            <!-- <van-radio name="2" icon-size="24px">单选框 2</van-radio> -->
          </van-radio-group>
        </div>
      </div>
     </div>
    <div>
      <div class="tite">二、判断题</div>
       <div v-for="(item,index) in judgeList" :key="index" class="mgtbi">
        <div class="textlp">{{index + 1}}.{{item.title}}</div>
        <div>
          <van-radio-group v-model="item.results">
            <van-radio v-for="(cont,inkey) in item.contents" :key="inkey" :name="inkey" icon-size="20px" class="textlp mgtop">{{cont}}</van-radio>
          </van-radio-group>
        </div>
      </div>
    </div>

      <div>
      <div class="tite">三、多选题</div>
      <div  v-for="(item,index) in multList" :key="index" class="mgtbi">
        <div>{{index + 1}}.{{item.title}}</div>
        <div>
          <van-checkbox-group v-for="(cont,inkey) in item.contents" :key="inkey" icon-size="20px" class="textlp mgtop" v-model="item.results" :max="item.contents.length">
            <van-checkbox :name="inkey + 1">{{cont}}</van-checkbox>
            <!-- <van-checkbox name="b">B:333</van-checkbox>
            <van-checkbox name="c">C:444</van-checkbox> -->
          </van-checkbox-group>
        </div>
      </div>
    </div>
    
    </div>
    <div>
      <van-button type="primary" block @click="submit">提交</van-button>
    </div>
  </div>
</template>

<script>
// import sHeader from '@/components/SimpleHeader'
import { Dialog, CountDown ,RadioGroup, Radio,Button,Toast} from 'vant';
import { examList, examResults } from '../service/userLogin'
import { nextTick } from 'vue';
export default {
  components: {
    // sHeader
  },
  data() {
    return {
      name: '考试',
      time: 30 * 60 * 1000,
      // time: 3 * 1000,
      user: {},
      arr: [1,2,3,4,5],
      result: [],
      result1: [],
      shows: true,
      billList: [], // 单选题目
      judgeList: [], // 判断题
      multList: [], // 多选题
      examinfo_id: '', // 考试题目id
      user_id: sessionStorage.getItem('userId-manage'),
    }
  },
  async mounted() {
    this.billList = [];
    this.judgeList = [];
    this.multList = [];
    let category_id = this.$route.query.category_id;
    // console.log(this.$store);
    let currentTime = new Date().getTime();
    // 首次进入页面
    if (this.$store.state.dadasd === '') {
      this.$store.commit('addTime',currentTime);
      // console.log('妹纸')
    } else { // 刷新页面，不从新计算
      // 刷新页面倒计时，不从新计算
      this.time = (30 * 60 * 1000) - (currentTime - this.$store.state.dadasd);
      
    }
   
    this.shows = true;
    const idData = {
      user_id: sessionStorage.getItem('userId-manage'),
      category_id: category_id,
    };
    const res = await examList(idData);
    if (!res) return;
    // 1已提交试卷   0没有交卷过
    if (res.data.user_exam_type === 2) {
      this.shows = false;
      return this.$router.push({ path: '/home' });
    }
    this.billList = res.data.select;
    this.judgeList = res.data.true_false;
    this.multList = res.data.multi_select;
    for (let i = 0; i < this.billList; i++) {
      this.billList[i].results = '';
    }
    for (let i = 0; i < this.judgeList; i++) {
      this.judgeList[i].results = '';
    }
    for (let g = 0; g < this.multList; g++) {
      this.multList[g].results = [];
    }
    this.examinfo_id = res.data.exam_info.id;

  },
  methods: {
    goBack() {
      this.$router.push({ path: '/home' });
    },
    goTo(r) {
      this.$router.push({ path: r })
    },
    // 倒计时结束
    async finish() {
      let arr = [];
      if (this.billList !== undefined) {
         this.billList.forEach( item => {
          // console.log(item.results,'ite');
          // 1--A  2--B  3--C  4--D  5-对  6--错
          if (item.results !== undefined && item.results !== '' ) {
            arr.push({
              id: item.id,
              answer: item.results + 1 + '',
            });
          }
        })
      }
     
      if (this.judgeList !== undefined) {
        this.judgeList.forEach( item => {
          // console.log(item.results,'ite');
          // 1--A  2--B  3--C  4--D  5-对  6--错
          if (item.results !== undefined && item.results !== '' ) {
            if (item.results === 0) {
              arr.push({
                id: item.id,
                answer: '5',
              });
            } else {
              arr.push({
                id: item.id,
                answer: '6',
              });
            }
            
          }
        });
      }

      if (this.multList !== undefined) {
        this.multList.forEach( item => {
          // console.log(item.results,'ite');
          // 1--A  2--B  3--C  4--D  5-对  6--错
          let list = [];
          if (item.results.length !== 0 && item.results !== undefined) {
            item.results.forEach(data => {
              // console.log(data,'data');
              // list.push()
              if (data <= 4) {
                list.push(data)
              } else if (data === 5) { // 如果选E
                list.push(7)
              } else { // 如果选F
                list.push(8)
              }
            })
            arr.push({
              id: item.id,
              answer: list,
            });
          }
        });
      }


      // 如果考试时间结束，一题没回答
      if (arr.length === 0) {
        Dialog.alert({
          message: '考试结束，您的考试分数为0',
        }).then(() => {
          this.shows = false;
          this.$router.push({ path: '/home' });
        });
      } else {
        const arrList = {
          examinfo_id: this.examinfo_id,
          user_id: sessionStorage.getItem('userId-manage'),
          answer_arr: arr,
        }
        const res = await examResults(arrList);
        if (!res) return;
        if (res.status === 'error') {
          Toast.fail(res.msg);
        } else {
          this.shows = false;
          Dialog.alert({
            title: '您的考试分数为'+ res.data.score,
            message: res.msg,
          }).then(() => {
            this.$router.push({ path: '/home' });
          });
          
        }
      }
    },
    async submit() {
      let arr = [];
      if (this.billList !== undefined) {
         this.billList.forEach( item => {
          // console.log(item.results,'ite');
          // 1--A  2--B  3--C  4--D  5-对  6--错
          if (item.results !== undefined && item.results !== '' ) {
            arr.push({
              id: item.id,
              answer: item.results + 1 + '',
            });
          }
        })
      }
      // console.log(this.judgeList,'this.judgeList');
      if (this.judgeList !== undefined) {
        this.judgeList.forEach( item => {
          // console.log(item,'ite');
          // 1--A  2--B  3--C  4--D  5-对  6--错
          if (item.results !== undefined && item.results !== '' ) {
            if (item.results === 0) {
              arr.push({
                id: item.id,
                answer: '5',
              });
            } else {
              arr.push({
                id: item.id,
                answer: '6',
              });
            }
            
          }
        });
      }

      if (this.multList !== undefined) {
        this.multList.forEach( item => {
          // console.log(item.results,'ite');
          // 1--A  2--B  3--C  4--D  5-对  6--错
          let list = [];
          if (item.results !== undefined && item.results.length !== 0) {
            item.results.forEach(data => {
              // console.log(data,'data');
              // list.push()
              if (data <= 4) {
                list.push(data)
              } else if (data === 5) { // 如果选E
                list.push(7)
              } else { // 如果选F
                list.push(8)
              }
            })
            arr.push({
              id: item.id,
              answer: list.join(','),
            });
          //   if (item.results >= 4) {
          //     arr.push({
          //       id: item.id,
          //       answer: item.results + 1,
          //     });
          //   } else {
          //     arr.push({
          //       id: item.id,
          //       answer: 6,
          //     });
          //   }
            
          }
        });
      }



      // 1--A  2--B  3--C  4--D  5-对  6--错' 7--E  8--F

      // let len = this.billList.length + this.judgeList.length + this.multList.length;
      // if (arr.length !== len) {
      //   return Toast.fail('请检查您的题目是否全部填写');
      // }
      const arrList = {
        examinfo_id: this.examinfo_id,
        user_id: sessionStorage.getItem('userId-manage'),
        answer_arr: arr,
      }
      const res = await examResults(arrList);
      if (!res) return;
      if (res.status === 'error') {
        Toast.fail(res.msg);
      } else {
        this.shows = false;
        // 如果考过了，就离开不考第二次
        if (res.data.score >= 60) {
          Dialog.alert({
            title: '您的考试分数为'+ res.data.score,
            message: res.msg,
          }).then(() => {
            this.$router.push({ path: '/home' });
          });
          return
        }

        // 不为2就有一次补考
        if (res.data.exam_times !== 2) {
           // Toast.fail(res.msg);
          Dialog.confirm({
            title: '您的考试分数为'+ res.data.score,
            message: res.msg + '您还有一次考试机会,即将开始第二次考试。',
            confirmButtonText: '重新考试',
            cancelButtonText: '退出'
          }).then(() => {
            sessionStorage.setItem("dadasd",'');
            let currentTime = new Date().getTime();
            this.$store.commit('addTime',currentTime);
            // this.$store.commit('addTime','');

            nextTick(()=> {
              // console.log('233232');
              this.time = 30 * 60 * 1000;
              this.againList();
            });
            
            
          }).catch(() => {
            this.$router.push({ path: '/home' });
          });
        } else {
          // 第二次考试不管考试结果，都要离开
          Dialog.alert({
            title: '您的考试分数为'+ res.data.score,
            message: res.msg,
          }).then(() => {
            this.$router.push({ path: '/home' });
          });
        }
       
        
      }

      // 考试信息id （对应考试题列表接口中examinfo里的id）
      
    },
    // 第二次考试获取题目
    async againList() {
      this.billList = [];
      this.judgeList = [];
      this.multList = [];
      const idData = {
        user_id: sessionStorage.getItem('userId-manage'),
        category_id: this.$route.query.category_id,
      };
      const res = await examList(idData);
      if (!res) return;

      this.billList = res.data.select;
      this.judgeList = res.data.true_false;
      this.multList = res.data.multi_select;
      for (let i = 0; i < this.billList; i++) {
        this.billList[i].results = '';
      }
      for (let i = 0; i < this.judgeList; i++) {
        this.judgeList[i].results = '';
      }
      for (let g = 0; g < this.multList; g++) {
        this.multList[g].results = [];
      }
      this.examinfo_id = res.data.exam_info.id;
    },
  },
  beforeRouteLeave (to, from, next) {
    if (this.shows === true) {
      Dialog.confirm({
        title: '提示',
        message: '您尚在考试，确定提交答案吗',
      }).then(() => {
        this.submit();
        // next();
      }).catch(() => {
        // this.$router.push({ path: '/examination' });
      });
    } else {
      this.$store.commit('addTime','');
      sessionStorage.removeItem('dadasd');
      next();
    }
    
    
    // to 去哪
    // next()
  }

}
</script>
<style lang="less" scoped>
@import '../common/style/mixin';
.user-box {
  .user-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    .fj();
    .wh(100%, 44px);
    line-height: 44px;
    padding: 0 10px;
    .boxSizing();
    color: #252525;
    background: #fff;
    border-bottom: 1px solid #dcdcdc;
    .user-name {
      font-size: 14px;
    }
  }
  .titHead {
    
    width: 80%;
    position: fixed;
    right: 20px;
    margin: 0 auto;
    // height: 40px;
    margin-top: 50px;
    line-height: 40px;
    font-size: 14px;
    text-align: left;
    color: red;
  }
}
.mgtbi {
  margin-top: 14px;
}
.textlp {
  letter-spacing: 3px;
}
.mgtop {
  margin-top: 6px;
}
.textr {
  width: 100%;
  color: red;
  text-align: right;
}
.bottoms {
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  padding-bottom: 20px;
}
.tite {
  font-size: 14px;
  font-weight: 700;
}
  .simple-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    .fj();
    .wh(100%, 44px);
    line-height: 44px;
    padding: 0 10px;
    .boxSizing();
    color: #252525;
    background: #fff;
    border-bottom: 1px solid #dcdcdc;
    .simple-header-name {
      font-size: 14px;
    }
  }

</style>
