<template>
  <div class="user-box">
    <s-header :name="'学习'"></s-header>
    <div class="user-info">
      <span>学习进度：{{counts}}/{{total}}</span>
    </div>
    <div class="bottoms" >
      <!-- v-for="(item,i) in list1"  -->
      <div class="flex wordimg" v-for="(item,index) in arr" :key="index" @click="learnClick(item)">
        <div class="imgs"><img src="//s.weituibao.com/1583583975067/user-graduate%20(1).png"/></div>
        <div class="fml-flex-2">{{item.title}}</div>
        <div class="fml-flex-1">{{item.showData === true ? '已学习' : '未学习' }}</div>
      </div>
    </div>
    <div class="pagcss">
      <van-pagination v-model="requst.current" :total-items="total" :items-per-page="10" @change="pagChang" />
    </div>
  </div>
</template>

<script>
import { Pagination, Toast } from 'vant';
import sHeader from '@/components/SimpleHeader'
import { learningContent, studyStatus, studyAdd } from '../service/userLogin'
import { nextTick } from 'vue';
export default {
  components: {
    sHeader
  },
  data() {
    return {
      user: {},
      arr: [1,2,3,4,5],
      total: 0,
      requst: {
        id: '',
        category_id: '',
        current: 1,
        pageSize: 10,
      },
      idList: [],
      ratioList: [],
      counts: 0, // 已学习数
    }
  },
  async mounted() {
    this.arr = [];
    this.ratioList = [];
    this.total = 0;
    this.counts = 0;
    // console.log(this.$route.query.id);
    this.requst.id = this.$route.query.id;
    this.requst.category_id = this.$route.query.category_id;
    this.requst.current = 1;
    this.studyStatus(); // 学习状态查询
    nextTick(() => {
      this.learningContent();
    })
    
    
    
    // this.user = data
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    goTo(r) {
      this.$router.push({ path: r })
    },
    async learningContent() {
      this.idList = [];
      let dais = [];
      const res = await learningContent(this.requst);
      if (!res) return;
      dais = res.data.list;
      this.total = res.data.pagination.total;
      // 循环对比找到已学习的id
      for (let i = 0; i < dais.length ; i++) {
        for (let p = 0; p < this.ratioList.length; p++) {
          if (dais[i].id === this.ratioList[p]) {
            dais[i].showData = true;
          }
            
        }
      }
      nextTick(() => {
        this.arr = dais;
      })
      
      // console.log(this.arr);
    },
    // 获取所有学习过的id
    async studyStatus() {
      const userId = {
        id: this.$route.query.id,
        category_id: this.$route.query.category_id,
        user_id: sessionStorage.getItem('userId-manage'),
      }
      const res = await studyStatus(userId);
      if (!res) return;
      this.ratioList = res.data.study_ids;
      this.counts = res.data.counts;
    },
    learnClick(item) {
      if (item.status === '正常') {
        // window.open(item.file_path);
        // console.log(item);
        // console.log(item.file_path);
        let names = '';
        let index = item.file_path.lastIndexOf('\.');    //获取-后边的字符串
        names= item.file_path.substring(index + 1, item.file_path.length);
        const userId = {
          study_id: item.id,
          user_id: sessionStorage.getItem('userId-manage'),
          category_id: item.category_id,
        }
        const res = studyAdd(userId); // 点击视为已学习
        if (!res) return;
        this.$router.push({ 
          path: 'learnFile',
          query:{
            tit: item.title,
            nameType: names,
            urlData: item.file_path,
          }
        });
      } else {
        Toast.fail('学习尚未开启');
      }
      
    },
    pagChang() {
      this.studyStatus(); // 学习状态查询
      nextTick(() => {
        this.learningContent();
      })
      // this.learningContent();
    },
  }
}
</script>

<style lang="less" scoped>
@import '../common/style/mixin';
.pagcss {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.user-box {
  .user-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    .fj();
    .wh(100%, 44px);
    line-height: 44px;
    padding: 0 10px;
    .boxSizing();
    color: #252525;
    background: #fff;
    border-bottom: 1px solid #dcdcdc;
    .user-name {
      font-size: 14px;
    }
  }
  .user-info {
    width: 80%;
    margin: 0 auto;
    height: 40px;
    margin-top: 50px;
    line-height: 40px;
    font-size: 16px;
    text-align: right;
  }
}
.bottoms {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 20px;
  // background-color: yellow;
}
.wordimg {
  margin-top: 10px;
  height: 70px;
  border-radius: 6px;
  background-color: #ccc;
  .imgs {
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    width: 50px;
    height: 50px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .textr {
    text-align: right;
  }
}
</style>
