<template>
  <div class="user-box">
    <s-header :name="'考试列表'"></s-header>
    <div class="user-info">
      <!-- <span>学习进度：{{counts}}/{{total}}</span> -->
    </div>
    <div class="bottoms" >
      <!-- v-for="(item,i) in list1"  -->
      <div class="flex wordimg" v-for="(item,index) in arr" :key="index" @click="learnClick(item)">
        <div class="imgs"><img src="//s.weituibao.com/1583583975067/user-graduate%20(1).png"/></div>
        <div class="fml-flex-2"></div>
        <div class="fml-flex-1">{{item.title}}</div>
      </div>
    </div>
    <!-- <div class="pagcss">
      <van-pagination v-model="requst.current" :total-items="total" :items-per-page="10" @change="pagChang" />
    </div> -->

    <van-dialog v-model="dialogShow" title="提示" :showConfirmButton='trueS'>
      <div class="contsize">
        <div>1、考试题目随机抽取,考试满分为100分,60分及格;</div>
        <div>2、考试时间为30分钟,请勿中途退出,中途退出直接结算当前已答题目,考试计时完成后会自动提交;</div>
        <div>3、第一次不及格会有一次补考机会,如若两次都不及格取最高分数;</div>
      </div>
      <div class="pdes">
        <van-button type="primary" round block class="btntop" color="#00BAFF" @click="onBeforeClose">确定</van-button>
      </div>
      
    </van-dialog>
  </div>
</template>

<script>
import { Pagination, Toast } from 'vant';
import sHeader from '@/components/SimpleHeader'
import { learningContent, studyStatus, studyAdd, examcatelist,examList } from '../service/userLogin'
export default {
  components: {
    sHeader
  },
  data() {
    return {
      trueS: false,
      dialogShow: false,
      user: {},
      arr: [1,2,3,4,5],
      total: 0,
      requst: {
        category_id: '',
        current: 1,
        pageSize: 10,
      },
      ratioList: [],
      counts: 0, // 已学习数
      ids: '',
    }
  },
  async mounted() {
    this.dialogShow = false;
    // console.log(this.$route.query.id);
    this.requst.category_id = this.$route.query.id;
    this.requst.current = 1;
    this.learningContent();
    
    // this.user = data
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    goTo(r) {
      this.$router.push({ path: r })
    },
    async learningContent() {
      const res = await examcatelist();
      if (!res) return;
      this.arr = res.data;
     
    },
    // 确定跳转
    onBeforeClose() {
      this.dialogShow = false;
      this.$store.commit('addTime','');
      this.$router.push({ 
        path: '/examination',
        query:{
          category_id: this.ids,
        } 
      });
    },
    async learnClick(item) {
      const idData = {
        user_id: sessionStorage.getItem('userId-manage'),
        category_id: item.id,
      };
      const res = await examList(idData);
      if (!res) return;
      this.ids = item.id;
      // 1已提交试卷   0没有交卷过
      if (res.data.user_exam_type === 1) {
        return Toast.fail('您已参加过考试');
      } else {
        this.dialogShow = true;
      }
      

      // this.$router.push({ 
      //     path: 'learnFile',
      //     query:{
      //       nameType: names,
      //       urlData: item.file_path,
      //     }
      //   });
      // if (item.status === '正常') {
      //   // window.open(item.file_path);
      //   // console.log(item);
      //   // console.log(item.file_path);
      //   let names = '';
      //   let index = item.file_path.lastIndexOf('\.');    //获取-后边的字符串
      //   names= item.file_path.substring(index + 1, item.file_path.length);
      //   const userId = {
      //     study_id: item.id,
      //     user_id: sessionStorage.getItem('userId-manage'),
      //     category_id: item.category_id,
      //   }
      //   const res = studyAdd(userId); // 点击视为已学习
      //   if (!res) return;
        
      // } else {
      //   Toast.fail('学习尚未开启');
      // }
      
    },
    pagChang() {
      this.learningContent();
    },
  }
}
</script>

<style lang="less" scoped>
@import '../common/style/mixin';
.pdes {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px;
}
.contsize {
  // margin-top: 8px;
  // padding-left: 5px;
  // padding-right: 5px;
  // font-size: 16px;
  margin-top: 8px;
  padding-left: 8px;
  padding-right: 6px;
  font-size: 16px;
  text-indent: 15px;
  white-space:normal;

word-break:break-all;

overflow:hidden;
}
.pagcss {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.user-box {
  .user-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    .fj();
    .wh(100%, 44px);
    line-height: 44px;
    padding: 0 10px;
    .boxSizing();
    color: #252525;
    background: #fff;
    border-bottom: 1px solid #dcdcdc;
    .user-name {
      font-size: 14px;
    }
  }
  .user-info {
    width: 80%;
    margin: 0 auto;
    height: 40px;
    margin-top: 50px;
    line-height: 40px;
    font-size: 16px;
    text-align: right;
  }
}
.bottoms {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 20px;
  // background-color: yellow;
}
.wordimg {
  margin-top: 10px;
  height: 70px;
  border-radius: 6px;
  background-color: #ccc;
  .imgs {
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    width: 50px;
    height: 50px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .textr {
    text-align: right;
  }
}
</style>
